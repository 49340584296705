export enum ContentTypes {
  ACCESS_CASHIER = 'accessCashier',
  ADDITIONAL_INFORMATION_FORM = 'additionalInformationForm',
  BANNER = 'banner',
  CASHIER = 'cashier',
  COOKIE_CONSENT = 'cookieConsent',
  STEPS = 'steps',
  COUNTRY = 'country',
  ENTER_LOGIN_FORM = 'enterCodeForm',
  ERROR = 'error',
  EXTERNAL_LINK = 'externalLink',
  FOOTER = 'footer',
  FORM = 'form',
  GAME = 'game',
  GAME_CATEGORY = 'gameCategory',
  GAME_LIST = 'gameList',
  GAME_PROVIDER = 'gameProvider',
  GAME_SEARCH_BOX = 'gameSearchBox',
  GAME_SEARCH_TEXT_FIELD = 'gameSearchTextField',
  GAME_TYPE = 'gameType',
  GAME_DESCRIPTION = 'gameDescription',
  GAME_STUDIO = 'gameStudio',
  GAME_CAMPAIGN = 'gameCampaign',
  LOCK_ACCOUNT_FORM = 'lockAccountForm',
  LOGIN_FORM = 'loginForm',
  MAINTENANCE_WINDOW = 'maintenanceWindow',
  MENU = 'menu',
  PAGE = 'page',
  SEO_BOX = 'seoBox',
  CUSTOM_CONTENT_BOX = 'customContentBox',
  TAG = 'tag',
  TRANSLATION = 'translation',
  UPDATE_ADDRESS_FORM = 'updateAddressForm',
  UPDATE_DEPOSIT_LIMIT_FORM = 'updateDepositLimitForm',
  UPDATE_LOSS_LIMIT_FORM = 'updateLossLimitForm',
  UPDATE_MARKETING_SETTINGS_FORM = 'updateMarketingSettingsForm',
  UPDATE_SESSION_TIME_LIMIT_FORM = 'updateSessionTimeLimitForm',
  UPDATE_WAGER_LIMIT_FORM = 'updateWagerLimitForm',
  UPDDATE_EMAIL_FORM = 'updateEmailForm',
  UPDDATE_PERSONAL_DETAILS_FORM = 'updatePersonalDetailsForm',
  IMAGE = 'image',
  SLIDE = 'slide',
  IMAGE_SLIDER = 'imageSlider',
  CARD_LIST = 'cardList',
  CARD = 'card',
  TEXT_SECTION = 'textSection',
  IMAGE_SECTION = 'imageSection',
  SIMPLE_SLIDE = 'simpleSlide',
  SIMPLE_IMAGE_SLIDER = 'simpleImageSlider',
  ERROR_404 = 'error404',
  PAYMENT_METHODS = 'paymentMethods',
  CONFIG = 'config',
  CURRENCY = 'currency',
  PAYMENT_PROVIDER = 'paymentProvider',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  PRIVACY_POLICY = 'privacyPolicy',
  LICENSE = 'license',
  REPOSITORY_GAME_CATEGORY = 'repositoryGameCategory',
  BONUS_PROGRAM = 'bonusProgram',
  UPDATE_MARKETING_AND_EMAIL_FORM = 'marketingAndEmailForm',
  PROMOTION = 'promotion',
  KYC_VALIDATION = 'kycValidation',
}
