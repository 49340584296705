import global from './global';
import refuel from './refuel';
import refuelcasino from './refuelcasino';
import mrgold from './mrgold';
import mountgold from './mountgold';
import ref from './ref';
import affiliates from './affiliates';
import dinotech from './dinotech';
import infiniza from './infiniza';
import tekzia from './tekzia';
import mountgoldCom from './mountgoldCom';
import mountgoldEu from './mountgoldEu';
import mountgoldIo from './mountgoldIo';
import { Repository } from '../types';

const schema: Repository[] = [
  global,
  refuel,
  refuelcasino,
  mrgold,
  mountgold,
  ref,
  affiliates,
  dinotech,
  infiniza,
  tekzia,
  mountgoldCom,
  mountgoldEu,
  mountgoldIo,
];

export default schema;
