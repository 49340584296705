import {
  createPage,
  createLoginForm,
  createEnterCodeForm,
  createGameSearchBox,
  createGameSearchTextField,
  createFooter,
  createCashier,
  createTranslation,
  createGameList,
  createMenu,
  createAdditionalInformationForm,
  createUpdateEmailForm,
  createUpdatePersonalDetailsForm,
  createUpdateAddressForm,
  createUpdateMarketingSettingsForm,
  createLockAccountForm,
  createUpdateDepositLimitForm,
  createUpdateWagerLimitForm,
  createUpdateLossLimitForm,
  createUpdateSessionTimeLimitForm,
  createAccessCashier,
  createSeoBox,
} from './builders';

export const locales = ['en', 'jp'];

export const page = createPage({
  locales,
  previewInStaging: ({ contentId }) => {
    console.log('preview in staing', contentId)
  }
});
export const seoBox = createSeoBox({ locales });
export const loginForm = createLoginForm({ locales });
export const enterCodeForm = createEnterCodeForm({ locales });
export const gameList = createGameList({ locales });
export const gameSearchBox = createGameSearchBox({ locales });
export const gameSearchTextField = createGameSearchTextField({ locales });
export const footer = createFooter({ locales });
export const cashier = createCashier({ locales });
export const translation = createTranslation({ locales });
export const menu = createMenu({ locales });
export const additionalInformationForm = createAdditionalInformationForm({ locales });
export const updateEmailForm = createUpdateEmailForm({ locales });
export const updatePersonalDetailsForm = createUpdatePersonalDetailsForm({ locales });
export const updateAddressForm = createUpdateAddressForm({ locales });
export const updateMarketingSettingsForm = createUpdateMarketingSettingsForm({ locales });
export const lockAccountForm = createLockAccountForm({ locales });
export const updateDepositLimitForm = createUpdateDepositLimitForm({ locales });
export const updateWagerLimitForm = createUpdateWagerLimitForm({ locales });
export const updateLossLimitForm = createUpdateLossLimitForm({ locales });
export const updateSessionTimeLimitForm = createUpdateSessionTimeLimitForm({ locales });
export const accessCashier = createAccessCashier({ locales });

const ref = {
  repository: 'ref',
  types: [
    accessCashier,
    additionalInformationForm,
    cashier,
    enterCodeForm,
    footer,
    gameList,
    gameSearchBox,
    gameSearchTextField,
    lockAccountForm,
    loginForm,
    menu,
    page,
    seoBox,
    translation,
    updateAddressForm,
    updateDepositLimitForm,
    updateEmailForm,
    updateLossLimitForm,
    updateMarketingSettingsForm,
    updatePersonalDetailsForm,
    updateSessionTimeLimitForm,
    updateWagerLimitForm,
  ],
};

export default ref;
