export const PLACEHOLDER = '-';

export enum PriorityEnum {
  LOW = 'LOW',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
}

export enum BrandEnum {
  NAC = 'NAC',
  PRANK = 'PRANK',
  MRGLD = 'MRGLD',
}

export enum TransactionType {
  Bet = 'Bet',
  Win = 'Win',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  AdjustmentDeposit = 'AdjustmentDeposit',
  RollbackCasinoWin = 'RollbackCasinoWin',
  RollbackCasinoBet = 'RollbackCasinoBet',
  AdjustmentWithdrawal = 'AdjustmentWithdrawal',
}

export enum ClosureType {
  Timeout = 'Timeout',
  SelfExclusion = 'SelfExclusion',
}

export enum LimitType {
  Loss = 'Loss',
  Wager = 'Wager',
  Deposit = 'Deposit',
  Session = 'Session',
  RealitySession = 'RealitySession',
}

export enum LimitTypeEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum DocumentType {
  ID = 'ID',
  Other = 'Other',
  Passport = 'Passport',
  CreditCard = 'CreditCard',
  UtilityBill = 'UtilityBill',
  BankStatement = 'BankStatement',
  DrivingLicense = 'DrivingLicense',
  SourceOfWealth = 'SourceOfWealth',
}

export enum DocumentVerificationType {
  Age = 'Age',
  Email = 'Email',
  Address = 'Address',
  Identity = 'Identity',
  PhoneNumber = 'PhoneNumber',
  SourceOfWealth = 'SourceOfWealth',
}

export enum DocumentStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum TimeoutReasonEnum {
  LosingTooMuch = 'LosingTooMuch',
  PlayingTooMuch = 'PlayingTooMuch',
  GamblingProblem = 'GamblingProblem',
}
